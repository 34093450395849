import { createStore } from "vuex";
// import Request from "../request";
// import router from "../router/router";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
