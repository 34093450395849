import { createRouter, createWebHashHistory } from "vue-router";
import IndexPage from "../views/Index.vue";

const routes = [
  {
    path: "/",
    name: "IndexPage",
    component: IndexPage,
  },
  {
    path: "/:catchAll(.*)",
    name: "IndexPage",
    component: IndexPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // 除非是按上一頁，才會回原本位置
      return savedPosition;
    } else {
      // 換頁的時候，都回置頂
      return {
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

export default router;
