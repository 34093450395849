<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style lang="scss">
.preLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: #0063f8;
  background: #000000;
  z-index: 99999;
  overflow: hidden;

  .spin {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 1s linear infinite;
    width: 70px;
    height: 70px;
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-radius: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
