<template>
  <!-- Preloader -->
  <div v-if="isLoading" class="preLoader">
    <span class="spin"></span>
  </div>

  <!-- Main header -->
  <header id="top" class="header">
    <div class="header-absoulate">
      <div class="main-header">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-3 col-lg-2 col-md-3 col-sm-5 col-7">
              <!-- Logo -->
              <!-- <div class="logo" data-animate="fadeInDown" data-delay=".5"> -->
              <div class="logo">
                <a href="#">
                  <img
                    data-rjs="2"
                    src="img/logo_digicosmos_white_horizon_small_chinese.png"
                    alt="Digicosmos"
                    style="width: 190px"
                  />
                  <img
                    data-rjs="2"
                    src="img/logo_digicosmos_black_horizon_small_chinese.png"
                    alt="Digicosmos"
                    style="width: 190px"
                  />
                </a>
              </div>
              <!-- End of Logo -->
            </div>
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-7 col-5">
              <!-- <nav data-animate="fadeInDown" data-delay=".75"> -->
              <nav>
                <!-- Header-menu -->
                <div class="header-menu">
                  <ul>
                    <li class="active mega-drop-down">
                      <a href="#top">
                        <template v-if="lang.en">Home</template>
                        <template v-if="lang.cht">首頁</template>
                      </a>
                    </li>
                    <li>
                      <a href="#services">
                        <template v-if="lang.en">Services</template>
                        <template v-if="lang.cht">我們的服務</template>
                      </a>
                    </li>
                    <li>
                      <a href="#partner">
                        <template v-if="lang.en">Partner</template>
                        <template v-if="lang.cht">合作夥伴</template>
                      </a>
                    </li>
                    <li>
                      <a href="#contact">
                        <template v-if="lang.en">Contact Us</template>
                        <template v-if="lang.cht">聯絡我們</template>
                      </a>
                    </li>
                    <li class="langSelector">
                      <span
                        @click="
                          lang.cht = true;
                          lang.en = false;
                        "
                        >中文</span
                      >
                      /
                      <span
                        @click="
                          lang.cht = false;
                          lang.en = true;
                        "
                        >EN</span
                      >
                    </li>
                  </ul>
                </div>
                <!-- End of Header-menu -->
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- End of Main header -->

  <!-- Banner -->
  <section class="main-banner banner--2 position-relative">
    <canvas id="c"></canvas>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-6">
          <!-- Banner content -->
          <div class="banner-content text-white">
            <h2
              data-animate="fadeInDown"
              data-delay=".5"
              class="animated fadeInDown"
              style="animation-duration: 0.6s; animation-delay: 0.5s"
            >
              <template v-if="lang.en"
                >Professional<br />Service Enhancement
              </template>
              <template v-if="lang.cht">
                心服務.夯技術.<br />神團隊.超品質
              </template>
            </h2>
            <p
              data-animate="fadeInLeft"
              data-delay=".7"
              class="animated fadeInLeft"
              style="animation-duration: 0.6s; animation-delay: 0.7s"
            >
              <template v-if="lang.en">
                10+ years of experience in the information security industry. We
                can provide corporate customers with solutions to protect data
                confidentiality and integrity.
              </template>
              <template v-if="lang.cht">
                我們的團隊在資訊安全行業已有十多年經驗，能提供完整且全方面的資訊安全防護建議，更能提供多樣化的資訊安全健檢服務，為企業客戶提供保障資料機密與完整性的解決方案。
              </template>
            </p>
            <ul
              class="list-inline animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".9"
              style="animation-duration: 0.6s; animation-delay: 0.9s"
            >
              <li>
                <a class="btn btn-transparent" href="#services">
                  <template v-if="lang.en">Check Services</template>
                  <template v-if="lang.cht">我們的服務</template>
                </a>
              </li>
              <!-- <li>
                <a class="btn btn-transparent" href="#">Explore more</a>
              </li> -->
            </ul>
          </div>
          <!-- End of Banner content -->
        </div>
        <div class="col-lg-6 col-12">
          <div class="banner-image">
            <img src="img/home-3/banner--3@2x.gif" alt="" />
            <img
              class="animate-icon animate-icon_1"
              src="img/home-3/animated@2x.png"
              alt=""
            />
            <img
              class="animate-icon animate-icon_2"
              src="img/home-3/animated@2x.png"
              alt=""
            />
            <img
              class="animate-icon animate-icon_3"
              src="img/home-3/animated@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End of Banner -->

  <!-- Get Solution -->
  <section class="pt-120 pb-70 home4-section-dark">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <!-- Section Title -->
          <div class="section-title text-center section-title--3 text-white">
            <h2
              data-animate="fadeInUp"
              class="section-title-get-solution"
              style="line-height: 50px"
            >
              <template v-if="lang.en">
                Can’t Find a Reliable Cyber Security Service?<br />
                Please Contact With Team Digicosmos
              </template>
              <template v-if="lang.cht">
                找不到值得信賴的資訊安全服務嗎？<br />
                請馬上聯繫我們的團隊
              </template>
            </h2>
            <!-- <p data-animate="fadeInUp" data-delay=".2">
              There are many variations of passages of Lorem Ipsum available,
            </p> -->
            <a class="btn-default" href="mailto:service@digicosmos.com.tw">
              <template v-if="lang.en">GET SOLUTION</template>
              <template v-if="lang.cht">取得解決方案</template>
            </a>
          </div>
          <!-- End of Section Title -->
        </div>
      </div>
    </div>
  </section>
  <!-- End of Get Solution Search -->

  <!-- Why Choose Us -->
  <section
    id="whyChooseUs"
    class="pt-120 pb-120 section--icon home4-section-darken"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9">
          <!-- Section title -->
          <div class="section-title text-center section-title--3 text-white">
            <h2 data-animate="fadeInUp">
              <template v-if="lang.en">Why Choose Us</template>
              <template v-if="lang.cht">為何選擇我們</template>
            </h2>
            <p data-animate="fadeInUp" data-delay=".2">
              <template v-if="lang.en">
                We can provide complete and all-round information security
                protection suggestions, as well as a variety of information
                security health check services
              </template>
              <template v-if="lang.cht">
                引進世界一流的資訊科技與產品，針對不同企業管理的實際需求規劃適宜的軟體<br />為客戶提供了高效率，高品質服務
              </template>
            </p>
          </div>
          <!-- End of Section title -->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="single-why-choose d-flex align-items-center">
            <div
              class="why-choose-icon animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".1"
              style="animation-duration: 0.6s; animation-delay: 0.1s"
            >
              <img
                data-rjs="2"
                src="img/home-3/icon/why_choose_1.svg"
                class="svg"
                alt=""
              />
            </div>
            <div class="why-choose-text">
              <div class="why-choose-text-inner">
                <h3
                  data-animate="fadeInUp"
                  data-delay=".2"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.2s"
                >
                  <template v-if="lang.en">10+ years Experience</template>
                  <template v-if="lang.cht">10年以上經驗</template>
                </h3>
                <p
                  data-animate="fadeInUp"
                  data-delay=".3"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.3s"
                >
                  <template v-if="lang.en">
                    We have more than ten years of experience in the information
                    security industry.
                  </template>
                  <template v-if="lang.cht">
                    我們的團隊在資訊安全行業已有十多年經驗，能提供完整且全方面的資訊安全防護建議
                  </template>
                </p>
              </div>
            </div>
          </div>
          <div class="single-why-choose d-flex align-items-center">
            <div
              class="why-choose-icon animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".1"
              style="animation-duration: 0.6s; animation-delay: 0.1s"
            >
              <img
                data-rjs="2"
                src="img/home-3/icon/why_choose_2.svg"
                class="svg"
                alt=""
              />
            </div>
            <div class="why-choose-text">
              <div class="why-choose-text-inner">
                <h3
                  data-animate="fadeInUp"
                  data-delay=".2"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.2s"
                >
                  <template v-if="lang.en">Customized Training</template>
                  <template v-if="lang.cht"> 客製化員工訓練 </template>
                </h3>
                <p
                  data-animate="fadeInUp"
                  data-delay=".3"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.3s"
                >
                  <template v-if="lang.en">
                    Empower your employees to detect threats and protect your
                    organization
                  </template>
                  <template v-if="lang.cht">
                    使您的員工能夠察覺攻擊或入侵威脅並保護您的公司資產
                  </template>
                </p>
              </div>
            </div>
          </div>
          <div class="single-why-choose d-flex align-items-center">
            <div class="why-choose-icon">
              <img
                data-rjs="2"
                src="img/home-3/icon/why_choose_3.svg"
                class="svg"
                alt=""
              />
            </div>
            <div class="why-choose-text">
              <div class="why-choose-text-inner">
                <h3
                  data-animate="fadeInUp"
                  data-delay=".2"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.2s"
                >
                  <template v-if="lang.en">Data Loss Solution</template>
                  <template v-if="lang.cht">資料外洩防護</template>
                </h3>
                <p
                  data-animate="fadeInUp"
                  data-delay=".3"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.3s"
                >
                  <template v-if="lang.en">
                    Fully managed service designed to help you discover,
                    classify, and protect your most sensitive data.
                  </template>
                  <template v-if="lang.cht">
                    評估可能外洩管道, 建立與既有系統整合的資料外洩防護機制
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="single-why-choose d-flex align-items-center">
            <div
              class="why-choose-icon animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".1"
              style="animation-duration: 0.6s; animation-delay: 0.1s"
            >
              <img
                data-rjs="2"
                src="img/home-3/icon/why_choose_4.svg"
                class="svg"
                alt=""
              />
            </div>
            <div class="why-choose-text">
              <div class="why-choose-text-inner">
                <h3
                  data-animate="fadeInUp"
                  data-delay=".2"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.2s"
                >
                  <template v-if="lang.en">Products Services</template>
                  <template v-if="lang.cht">產品端服務</template>
                </h3>
                <p
                  data-animate="fadeInUp"
                  data-delay=".3"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.3s"
                >
                  <template v-if="lang.en">
                    Product service systems, put simply, are when a firm offers
                    a mix of both products and services.
                  </template>
                  <template v-if="lang.cht">
                    為客戶創造價值的產品售後服務. 是公司環境戰略的重要組成部分
                  </template>
                </p>
              </div>
            </div>
          </div>
          <div class="single-why-choose d-flex align-items-center">
            <div
              class="why-choose-icon animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".1"
              style="animation-duration: 0.6s; animation-delay: 0.1s"
            >
              <img
                data-rjs="2"
                src="img/home-3/icon/why_choose_5.svg"
                class="svg"
                alt=""
              />
            </div>
            <div class="why-choose-text">
              <div class="why-choose-text-inner">
                <h3
                  data-animate="fadeInUp"
                  data-delay=".2"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.2s"
                >
                  <template v-if="lang.en">Defend your endpoints</template>
                  <template v-if="lang.cht">端點防護</template>
                </h3>
                <p
                  data-animate="fadeInUp"
                  data-delay=".3"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.3s"
                >
                  <template v-if="lang.en">
                    All the endpoint security capabilities you need in a single,
                    high-performance solution
                  </template>
                  <template v-if="lang.cht">
                    提供企業客戶針對各環境內所提供之端點防護建議，加強電腦的防護
                  </template>
                </p>
              </div>
            </div>
          </div>
          <div class="single-why-choose d-flex align-items-center">
            <div
              class="why-choose-icon animated fadeInUp"
              data-animate="fadeInUp"
              data-delay=".1"
              style="animation-duration: 0.6s; animation-delay: 0.1s"
            >
              <img
                data-rjs="2"
                src="img/home-3/icon/why_choose_6.svg"
                class="svg"
                alt=""
              />
            </div>
            <div class="why-choose-text">
              <div class="why-choose-text-inner">
                <h3
                  data-animate="fadeInUp"
                  data-delay=".2"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.2s"
                >
                  <template v-if="lang.en">24/7 support</template>
                  <template v-if="lang.cht">7 x 24 支援</template>
                </h3>
                <p
                  data-animate="fadeInUp"
                  data-delay=".3"
                  class="animated fadeInUp"
                  style="animation-duration: 0.6s; animation-delay: 0.3s"
                >
                  <template v-if="lang.en">
                    We offer dependable, on-demand support options including
                    24/7 technical and remote support
                  </template>
                  <template v-if="lang.cht">
                    7x24小時全天候守護, 都會有人待命替您服<br />隨時維繫客戶關係,
                    全方位滿足企業需求
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End of Why Choose Us -->

  <!-- Service -->
  <section id="services" class="pt-120 pb-90 section--icon home4-section-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9">
          <!-- Section title -->
          <div class="section-title text-center section-title--3 text-white">
            <h2 data-animate="fadeInUp">
              <template v-if="lang.en">Our Main Services</template>
              <template v-if="lang.cht">我們的服務</template>
            </h2>
            <!-- <p data-animate="fadeInUp" data-delay=".2">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour
            </p> -->
          </div>
          <!-- End of Section title -->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <!-- Single Service -->
          <div
            class="single-service dark"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            <div class="single-service-inner">
              <div class="single-service-front">
                <div class="service-icon">
                  <img
                    src="img/home-3/icon/Attacker-Hacker-512x512-reverse-rgb.png"
                    class="svg"
                    alt=""
                  />
                </div>
                <h3>
                  <template v-if="lang.en">APT Detection</template>
                  <template v-if="lang.cht">偵測進階持續性滲透攻擊</template>
                </h3>
                <!-- <p>
                  It is a long established fact that a reader will be The point
                  of using Lorem Ipsum is that it .
                </p> -->
              </div>
              <div class="single-service-back">
                <div class="service-hover-inner">
                  <!-- <img
                    src="img/home-4/icon/service_hover_1.svg"
                    class="svg"
                    alt=""
                  /> -->
                  <p>
                    <template v-if="lang.en">
                      APT (Advanced Persistent Threat) is a prevalent type of
                      cyber attack that involves a prolonged and persistent
                      infiltration and monitoring by attackers. The attackers
                      behind APTs often seize the information they need by
                      taking advantage of users' momentary negligence.
                    </template>
                    <template v-if="lang.cht">
                      APT(Advanced Persistent Threat)
                      屬最新常見的網路攻擊型態，APT
                      的攻擊者往往都會透過【長時間且持續性】的潛伏及監控，趁使用者稍有疏忽時撈取其所需要的資訊。
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Single Service -->
        </div>
        <div class="col-lg-4 col-md-6">
          <!-- Single Service -->
          <div
            class="single-service dark"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            <div class="single-service-inner">
              <div class="single-service-front">
                <div class="service-icon">
                  <img
                    src="img/home-3/icon/Trojan Horse-512x512-reverse-rgb-Triangle_201905011337.png"
                    class="svg"
                    alt=""
                  />
                </div>
                <h3>
                  <template v-if="lang.en">APT Prevention</template>
                  <template v-if="lang.cht">預防進階持續性滲透攻擊</template>
                </h3>
                <!-- <p>
                  It is a long established fact that a reader will be The point
                  of using Lorem Ipsum is that it .
                </p> -->
              </div>
              <div class="single-service-back">
                <div class="service-hover-inner">
                  <!-- <img
                    src="img/home-4/icon/service_hover_1.svg"
                    class="svg"
                    alt=""
                  /> -->
                  <p>
                    <template v-if="lang.en">
                      Provide you with "Professional Services", detect important
                      hosts in the environment, provide professional analysis
                      reports and suggestions for environmental improvement, and
                      effectively prevent various network attacks.
                    </template>
                    <template v-if="lang.cht">
                      提供您「專家級服務」，針對環境內的重要主機進行檢測，提供專業分析報告及環境改善建議，進而有效防止各種網路攻擊。
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Single Service -->
        </div>
        <div class="col-lg-4 col-md-6">
          <!-- Single Service -->
          <div
            class="single-service dark"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            <div class="single-service-inner">
              <div class="single-service-front">
                <div class="service-icon">
                  <img
                    src="img/home-3/icon/Web-512x512-reverse-rgb_Triangle.png"
                    class="svg"
                    alt=""
                  />
                </div>
                <h3>
                  <template v-if="lang.en">Web Application Firewall</template>
                  <template v-if="lang.cht">Web 應用入侵防禦系統</template>
                </h3>
                <!-- <p>
                  It is a long established fact that a reader will be The point
                  of using Lorem Ipsum is that it .
                </p> -->
              </div>
              <div class="single-service-back">
                <div class="service-hover-inner">
                  <!-- <img
                    src="img/home-4/icon/service_hover_2.svg"
                    class="svg"
                    alt=""
                  /> -->
                  <p>
                    <template v-if="lang.en">
                      We provide a Web Application Firewall (WAF) that offers
                      immediate and efficient protection to safeguard the
                      security of web applications and websites in enterprise
                      environments, defending against various known and unknown
                      attacks. Our solution also offers real-time protection
                      against the latest security threats.
                    </template>
                    <template v-if="lang.cht">
                      提供 Web 應用入侵防禦系統 (Web Application
                      Firewall)，立即與高效的防護系統協助企業環境保護
                      Web應用程式及網站安全，免受已知或未知的各種攻擊，也即時提供最新的安全問題防護。
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Single Service -->
        </div>
        <div class="col-lg-4 col-md-6">
          <!-- Single Service -->
          <div
            class="single-service dark"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            <div class="single-service-inner">
              <div class="single-service-front">
                <div class="service-icon">
                  <img
                    src="img/home-3/icon/Online Documentation-512x512-reverse-Triangle.png"
                    class="svg"
                    alt=""
                  />
                </div>
                <h3>
                  <template v-if="lang.en">Data Loss Prevention</template>
                  <template v-if="lang.cht">DLP 資料外洩防護</template>
                </h3>
                <!-- <p>
                  It is a long established fact that a reader will be The point
                  of using Lorem Ipsum is that it .
                </p> -->
              </div>
              <div class="single-service-back">
                <div class="service-hover-inner">
                  <!-- <img
                    src="img/home-4/icon/service_hover_3.svg"
                    class="svg"
                    alt=""
                  /> -->
                  <p>
                    <template v-if="lang.en">
                      We offer comprehensive data leak prevention measures that
                      can help prevent confidential information from being
                      leaked due to human error, thereby reducing the risk of
                      potential losses for businesses.
                    </template>
                    <template v-if="lang.cht">
                      提供完善的資料外洩防護，可避免由於人為因素導致的機密資料外洩，降低企業可能遭受損失的風險。
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Single Service -->
        </div>
        <div class="col-lg-4 col-md-6">
          <!-- Single Service -->
          <div
            class="single-service dark"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            <div class="single-service-inner">
              <div class="single-service-front">
                <div class="service-icon">
                  <img
                    src="img/home-3/icon/Working at Home_512x512-reverse_cmyk_triangle-01.png"
                    class="svg"
                    alt=""
                  />
                </div>
                <h3>
                  <template v-if="lang.en">
                    Security Awareness Training
                  </template>
                  <template v-if="lang.cht">資安職能訓練</template>
                </h3>
                <!-- <p>
                  It is a long established fact that a reader will be The point
                  of using Lorem Ipsum is that it .
                </p> -->
              </div>
              <div class="single-service-back">
                <div class="service-hover-inner">
                  <!-- <img
                    src="img/home-4/icon/service_hover_4.svg"
                    class="svg"
                    alt=""
                  /> -->
                  <p>
                    <template v-if="lang.en">
                      We provide customized and regular social engineering
                      training exercises to help enterprise clients understand
                      their employees' level of information security awareness
                      and identify areas where targeted efforts can be made to
                      strengthen user security awareness, thereby effectively
                      preventing various types of cyber attacks.
                    </template>
                    <template v-if="lang.cht">
                      提供您客製化與定期進行社交工程演練，幫助企業客戶了解員工對於資訊安全的觀念評分，並針對性加強使用者資訊安全意識，進而有效防止各種網路攻擊。
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Single Service -->
        </div>
        <div class="col-lg-4 col-md-6">
          <!-- Single Service -->
          <div
            class="single-service dark"
            data-animate="fadeInUp"
            data-delay=".1"
          >
            <div class="single-service-inner">
              <div class="single-service-front">
                <div class="service-icon">
                  <img
                    src="img/home-3/icon/WannaCry-512x512-reverse-rgb.png"
                    class="svg"
                    alt=""
                  />
                </div>
                <h3>
                  <template v-if="lang.en">Endpoint Protection</template>
                  <template v-if="lang.cht">端點防護</template>
                </h3>
                <!-- <p>
                  It is a long established fact that a reader will be The point
                  of using Lorem Ipsum is that it .
                </p> -->
              </div>
              <div class="single-service-back">
                <div class="service-hover-inner">
                  <!-- <img
                    src="img/home-4/icon/service_hover_5.svg"
                    class="svg"
                    alt=""
                  /> -->
                  <p>
                    <template v-if="lang.en">
                      We offer enterprise clients endpoint protection
                      recommendations tailored to their specific environments,
                      bolstering computer defense and preventing problems from
                      entering the corporate environment, thereby making it more
                      difficult for hackers to evade detection.
                    </template>
                    <template v-if="lang.cht">
                      提供企業客戶針對各環境內所提供之端點防護建議，加強電腦的防護，讓問題阻擋在企業環境外，使駭客無所遁形。
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- End of Single Service -->
        </div>
      </div>
    </div>
  </section>
  <!-- End of Service -->

  <!-- Our clients -->
  <section id="partner" class="pt-100 pb-100 home4-section-dark">
    <div class="container">
      <ul
        class="our-clients clients-style--2 list-unstyled d-md-flex justify-content-md-between mb-0"
      >
        <li data-animate="fadeInUp" data-delay=".1">
          <img
            data-rjs="2"
            src="img/TM_logo_reversed.png"
            alt=""
            style="width: 172px"
          />
        </li>
        <li data-animate="fadeInUp" data-delay=".2">
          <img
            data-rjs="2"
            src="img/PANW_Parent_Brand_Primary_Logo_RGB_Red_White.png"
            alt=""
            style="
              width: 190px;
              position: relative;
              top: 17px;
              filter: brightness(0) invert(1);
            "
          />
        </li>
        <li data-animate="fadeInUp" data-delay=".3">
          <img
            data-rjs="2"
            src="img/F5-Networks.png"
            alt=""
            style="
              height: 64px;
              filter: grayscale(100%);
              -webkit-filter: grayscale(100%);
            "
          />
        </li>
        <li data-animate="fadeInUp" data-delay=".4">
          <img
            data-rjs="2"
            src="img/lkc.mixed.logo.5f7bb733.svg"
            alt=""
            style="width: 137px; position: relative; top: 8px"
          />
        </li>
        <li data-animate="fadeInUp" data-delay=".5">
          <img
            data-rjs="2"
            src="img/NS_LOGO_COL_NEG_RGB.svg"
            alt=""
            style="
              width: 158px;
              position: relative;
              top: 24px;
              filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
            "
          />
        </li>
        <!-- <li data-animate="fadeInUp" data-delay=".6">
          <img
            data-rjs="2"
            src="img/forcepoint_logo.png"
            alt=""
            style="
              position: relative;
              top: -22px;
              width: 174px;
              filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
            "
          />
        </li> -->
      </ul>
    </div>
  </section>
  <!-- End of Our clients -->

  <!-- Footer -->
  <footer id="contact" class="main-footer text-white main-footer--3 mt-0">
    <div class="top-footer">
      <div class="container">
        <div class="row">
          <!-- Footer posts -->
          <div class="col-lg-5 col-sm-6">
            <div class="footer-widget">
              <div class="logo" data-animate="fadeInUp" data-delay=".0">
                <a href="#">
                  <img
                    src="img/logo_digicosmos_white_vertical.png"
                    data-rjs="2"
                    alt=""
                    style="width: 228px"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- End of Footer posts -->

          <!-- Footer contacts -->
          <div class="col-lg-3 col-sm-6">
            <div class="footer-widget">
              <h3 class="widget-title" data-animate="fadeInUp" data-delay=".35">
                <template v-if="lang.en">Content</template>
                <template v-if="lang.cht">內容</template>
              </h3>
              <div class="widget_nav_menu">
                <ul class="list-unstyled">
                  <li data-animate="fadeInUp" data-delay=".4">
                    <a href="#whyChooseUs">
                      <template v-if="lang.en">Why Choose Us?</template>
                      <template v-if="lang.cht">為何選擇我們</template>
                    </a>
                  </li>
                  <li data-animate="fadeInUp" data-delay=".45">
                    <a href="#services">
                      <template v-if="lang.en">Our Main Services</template>
                      <template v-if="lang.cht">我們的服務</template>
                    </a>
                  </li>
                  <li data-animate="fadeInUp" data-delay=".5">
                    <a href="#partner">
                      <template v-if="lang.en">Patners</template>
                      <template v-if="lang.cht">合作夥伴</template>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- End of Footer contacts -->
          <!-- Footer form -->
          <div class="col-lg-4 col-sm-6">
            <div class="footer-widget">
              <h3 class="widget-title" data-animate="fadeInUp" data-delay=".65">
                <template v-if="lang.en">Contact Us</template>
                <template v-if="lang.cht">聯絡我們</template>
              </h3>
              <div class="footer-contacts footer-contacts--3">
                <ul class="list-unstyled">
                  <li data-animate="fadeInUp" data-delay=".7">
                    <span><i class="fas fa-mobile-alt"></i></span>
                    <a href="tel:+886226579168">
                      <template v-if="lang.en">(+886) 2 2657 9168</template>
                      <template v-if="lang.cht">(02) 2657 9168</template>
                    </a>
                  </li>
                  <li data-animate="fadeInUp" data-delay=".75">
                    <span><i class="fas fa-envelope"></i></span>
                    <a href="mailto:service@digicosmos.com.tw"
                      >service@digicosmos.com.tw</a
                    >
                  </li>
                  <li data-animate="fadeInUp" data-delay=".8">
                    <span><i class="fas fa-map-marker-alt"></i></span>
                    <address>
                      <template v-if="lang.en">
                        8F., No. 32, Aly. 407, Sec. 2, Tiding Blvd., Neihu
                        Dist., Taipei City 114 , Taiwan (R.O.C.)
                      </template>
                      <template v-if="lang.cht">
                        114台北市內湖區堤頂大道二段407巷32號8樓
                      </template>
                    </address>
                    (<a
                      href="https://goo.gl/maps/p2egACyao43E6Zar7"
                      target="_blank"
                      >map</a
                    >)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- End of Footer form -->
        </div>
      </div>
    </div>
    <!-- Bottom footer -->
    <div class="bottom-footer position-relative">
      <div class="container">
        <div class="row align-items-center text-center">
          <div class="col-12">
            <span class="d-block"
              >Copyright © {{ currentYear }} Digicosmos CO.,LTD. All Rights
              Reserved.
            </span>
          </div>
        </div>
      </div>
      <!-- Back to top -->
      <button type="button" class="back-to-top back-top--3">
        <img src="img/home-4/icon/back_top.svg" class="svg" alt="" />
      </button>
      <!-- End of Back to top -->
    </div>
    <!-- End of Bottom footer -->
  </footer>
  <!-- End of Footer -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "../components/HelloWorld.vue";

export default {
  name: "IndexPage",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      isLoading: true,
      lang: {
        cht: true,
        en: false,
      },
      currentYear: "",
    };
  },
  mounted() {
    this.currentYear = new Date().getFullYear();

    setTimeout(() => {
      this.isLoading = false;
      this.initialMatrixEffectSection();
    }, 1000);
  },
  methods: {
    initialMatrixEffectSection() {
      // geting canvas by Boujjou Achraf
      var c = document.getElementById("c");
      var ctx = c.getContext("2d");

      //making the canvas full screen
      c.height = window.innerHeight;
      c.width = window.innerWidth;

      //chinese characters - taken from the unicode charset
      // var matrix =
      // "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}";

      var matrix = "fohwefhfhieowfhewoi;fh;ow";
      //converting the string into an array of single characters
      matrix = matrix.split("");

      var font_size = 10;
      var columns = c.width / font_size; //number of columns for the rain
      //an array of drops - one per column
      var drops = [];
      //x below is the x coordinate
      //1 = y co-ordinate of the drop(same for every drop initially)
      for (var x = 0; x < columns; x++) drops[x] = 1;

      //drawing the characters
      function draw() {
        //Black BG for the canvas
        //translucent BG to show trail
        // ctx.fillStyle = "rgba(0, 0, 0, 0.04)";
        ctx.fillStyle = "rgba(23,100,227, .2)";
        ctx.fillRect(0, 0, c.width, c.height);

        // ctx.fillStyle = "#78C475";
        ctx.fillStyle = "rgba(255,255,255,.3)";
        // ctx.fillStyle = "#ffffff";
        ctx.font = font_size + "px arial";
        //looping over drops
        for (var i = 0; i < drops.length; i++) {
          //a random chinese character to print
          var text = matrix[Math.floor(Math.random() * matrix.length)];
          //x = i*font_size, y = value of drops[i]*font_size
          ctx.fillText(text, i * font_size, drops[i] * font_size);

          //sending the drop back to the top randomly after it has crossed the screen
          //adding a randomness to the reset to make the drops scattered on the Y axis
          if (drops[i] * font_size > c.height && Math.random() > 0.975)
            drops[i] = 0;

          //incrementing Y coordinate
          drops[i]++;
        }
      }

      setInterval(draw, 35);
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
  z-index: 9;
}
.btn-default {
  display: inline-block;
  padding: 16px 45px;
  color: #fff;
  font-size: 16px;
  background-color: #00dbcb;
  border-radius: 50px;
}

.langSelector {
  cursor: pointer;
  color: #fff;
}

.home4-section-darken {
  background-color: #083f9a;
}
.single-why-choose {
  background-color: #2359c4;
  &:hover {
    background-color: #166be4;
  }
  &:hover .why-choose-text {
    background-color: #2359c4;
  }
}
.why-choose-text {
  background-color: #166be4;
}

.single-service.dark .single-service-back p {
  padding: 0 32px;
  color: #2962df;
  text-align: left;
}
</style>
